<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
      <b-row>
        <b-col cols="12" md="6">
          <div class="d-flex justify-content-between align-items-center py-3">
            <label for="statu" class="h6">Kampanya Aktif/Pasif Et</label>
            <b-form-checkbox :checked="form.kampanya_durum" v-model="form.kampanya_durum" switch size="lg" />
          </div>
        </b-col>
      </b-row>
      <b-row v-if="form.kampanya_durum">
        <b-col cols="12" md="6">
          <b-form-group label="İndirim Türü" v-if="kampanyalar.length">
            <v-select
              v-model="form.kampanya_k_no"
              :options="kampanyalar"
              :reduce="(kampanya) => kampanya.k_no"
              :clearable="false"
              class="invoice-filter-select d-block select-size-lg"
            >
              <template slot="selected-option" slot-scope="option">
                {{ option.icerik[defaultDil].baslik }}
              </template>
              <template slot="option" slot-scope="option">
                {{ option.icerik[defaultDil].baslik }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
              <i class="fad fa-save pr-2"></i>
              <span class="align-middle">{{ form.k_no == null ? 'EKLE' : 'GÜNCELLE' }}</span>
            </b-button>
            <b-button squared size="lg" variant="danger">
              <i class="fad fa-trash"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import { langs } from '@/libs/languages';
export default defineComponent({
  components: {
    vSelect,
  },

  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.firmaKNo = computed(() => store.getters.getUserData.firma_k_no);
    expo.kampanyalar = computed(() =>
      store.getters.getKampanyalar.filter((x) => x.kampanya_turu == 'adet' || x.kampanya_turu == 'fiyat')
    );
    expo.form = ref({
      k_no: null,
      kampanya_durum: false,
      kampanya_k_no: null,
    });

    const handlerGetAllData = async () => {
      context.emit('show', true);

      await store.dispatch('KampanyaAyarGetir').then((res) => {
        if (res.data.success && res.data.data) {
          expo.form.value = res.data.data;
        }
      });
      context.emit('show', false);
    };

    handlerGetAllData();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('KampanyaAyarOlustur', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
            context.emit('show', false);
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: 'bottom-left' });
          }
        });
    };

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
